import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const headMeta = {
  title: 'Thank You For Contacting Us | Pandev Law',
  description: 'Thank you for getting in touch with us!',
};

function ThankYou({ location }) {
  return (
    <Layout location={location} headMeta={headMeta}>
      <div className="container min-content-height">
        <div className="d-flex justify-content-center align-items-center min-content-height">
          <div className="section pt-5 pb-5 pt-md-10">
            <div className="text-center js-state-success mb-4">
              <h2 className="text-hero mb-5">Thank You!</h2>
              <h3 className="text-body">
                We will review your message and get back to you as soon as possible.
              </h3>
              <a
                href="/contact/"
                className="btn btn-secondary d-block d-md-inline-block mt-5">
                GO BACK
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default ThankYou;
